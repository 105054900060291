'use client';

import { TimelineLite } from 'gsap';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

//* HOC's
import { withLanguageContext, withUIContext } from '@/context';

//* Components
import { CustomLink } from '@/components/common';

//* Helpers
import stringToLetters from '@/helpers/stringToLetters';

//* Style
import ButtonStyle from './style';

const Button = (props) => {
	//! Ref
	const refEl = useRef();

	//! State
	const [act, setAct] = useState(false);

	const letterifyText = useMemo(() => {
		if (props.splitBtn) {
			let text = props.translate(props.splitBtn);
			return stringToLetters(text);
		}
	}, [props.splitBtn]);

	const tl = useMemo(() => new TimelineLite({}), []);

	useEffect(() => {
		if (act && props.winWidth >= 1280) {
			tl.to(
				refEl.current.childNodes[0].childNodes,
				0.1,
				{
					y: '-250%',
					stagger: 0.02,
				},
				0
			).to(
				refEl.current.childNodes[1].childNodes,
				0.1,
				{
					y: '0%',
					stagger: 0.02,
				},
				0.08
			);
		}
		act ? tl.play() : tl.reverse();
	}, [act]);

	const handleHover = useCallback((enter) => {
		if (enter) {
			setAct(true);
		} else {
			setAct(false);
		}
	}, []);

	//! Button Type
	const btn = useMemo(() => {
		if (props.link) {
			return (
				<ButtonStyle>
					<CustomLink
						url={props.link}
						className={`btn ${props.className || ''}`}>
						{props.children || props.translate(props.text)}
					</CustomLink>
				</ButtonStyle>
			);
		} else if (props.splitBtn) {
			return (
				<ButtonStyle>
					{props.btnLink ? (
						<CustomLink url={props.btnLink}>
							<span
								ref={refEl}
								className={`btn ${props.className || ''}`}
								onMouseEnter={() => handleHover(true)}
								onMouseLeave={() => handleHover(false)}>
								{letterifyText}
								{letterifyText}
							</span>
						</CustomLink>
					) : (
						<button
							ref={refEl}
							className={`${props.className || ''}`}
							onClick={props.onClick}
							onMouseEnter={() => handleHover(true)}
							onMouseLeave={() => handleHover(false)}>
							{letterifyText}
							{letterifyText}
						</button>
					)}
				</ButtonStyle>
			);
		} else {
			return (
				<ButtonStyle>
					<button
						disabled={props.disabled}
						className={props.className || ''}
						onClick={props.onClick}>
						<span>{props.children || props.translate(props.text)}</span>
					</button>
				</ButtonStyle>
			);
		}
	}, [props]);

	return btn;
};

export default withUIContext(withLanguageContext(Button, ['translate']), ['winWidth']);
